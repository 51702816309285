import React from "react"
import Bandeau from "../components/bandeau"
import Image from 'react-bootstrap/Image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
  }

const BonAchat = () => (

    <Bandeau title="Bon cadeau" dark>
        <Container>
            <br></br>
            <Row className="align-items-center ">
                <Col sm lg={7}>
                    <Image src={getPicturePath('bon_cadeau_hhc.png')} width="90%" className="m-0" />
                </Col>
                <Col sm lg={5}>
                    <p className="justified">
                        Anniversaire, Mariage, Souvenir ?
                        <br></br>
                        <br></br>
                        Plaisir d’offrir... N’hésitez pas à inviter vos proches !
                        <br></br>
                        <br></br>
                        Vous avez une idée ? Je la crée pour vous...
                        <br></br>
                        <br></br>
                        Vous n'avez pas d'idée ? Je vous en propose une !

                    </p>
                </Col>  
            </Row>          
        </Container>
    </Bandeau>

)
export default BonAchat
