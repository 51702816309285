import React from "react"
import Bandeau from "../components/bandeau"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function getPicturePath(imgName) {
  const images = require.context('../images/', true);
  return images('./' + imgName)
}

const AtelierHoraires = () => (
  <Bandeau title="Horaires">


    <Row className="align-items-center ">

      <Col lg={7} className='p-5'>
        <p>
          Durée du cours : <b>3h</b>

        </p>
        <ul>
          <li>Matin : 9h30 – 12h30 </li>
          <li>Après- midi : 14h – 17h00</li>
          <li>Samedi : 9h30 – 12h30</li>
        </ul>

        <p>Nombre d’élèves en cours : <b>4 à 5</b> personnes maximum.</p>
        <p className="justified">
          Le nombre restreint de participants permet une meilleure qualité de la
          prestation. Cela permet aussi de rester à votre écoute avec une bien
          meilleure attention et une plus grande disponibilité.

        </p>
      </Col>

      <Col lg={5}>
        <Image src={getPicturePath("atelier_exterieur.jpg")} rounded width="100%" className="mb-3 mt-3" />
      </Col>
    </Row>


  </Bandeau>
)
export default AtelierHoraires
