import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BonAchat from "../containers/bon-achat"
import AtelierDescr from "../containers/atelier-descr"
import AtelierHoraires from "../containers/atelier-horaires"
import AtelierStage from "../containers/atelier-stage"
import AtelierTemoignage from "../containers/atelier-temoignage"


const AtelierPage = () => (
  <Layout>
    <SEO title="Atelier" />
    <br/><br/>
    <AtelierDescr/>
    <AtelierHoraires/>
    <AtelierStage/>
    <AtelierTemoignage/>
    <BonAchat/>
  </Layout>

)
export default AtelierPage
