import React from "react"
import Bandeau from "../components/bandeau"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function getPicturePath(imgName) {
  const images = require.context('../images/', true);
  return images('./' + imgName)
}

const AtelierStage = () => (
  <Bandeau title="Stages" dark>
    <Row className="align-items-center ">
      <Col lg={5}>
        <Image src={getPicturePath("atelier_interieur_2.jpg")} rounded width="100%" className="mb-3 mt-3" />
      </Col>
      <Col lg={7} className='p-5'>
        <p className="justified">
          <b>Un objet choisi sera réalisé à l’issue du stage</b> (À l'atelier ou à domicile.)
          <br></br>

        </p>
        <p className="justified">

          Le stage est idéal pour se retrouver entre amies ou en famille le temps d’une pause.
          Prendre un instant  pour se faire plaisir et partager un moment dans la joie.
          Je vous propose des stages de cartonnage d’un à deux jours,
          à l'atelier ou à votre domicile afin de réaliser un objet choisi ensemble.
    </p>
    <p>Nombre d’élèves : minimum 2 / maximum 6</p>
      </Col>
    </Row>

  </Bandeau>
)
export default AtelierStage
