import React from "react"
import Bandeau from "../components/bandeau"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function getPicturePath(imgName) {
  const images = require.context('../images/', true);
  return images('./' + imgName)
}


const AtelierDescr = () => (
  <Bandeau title="Atelier" dark>
    <Row className="align-items-center ">
      <Col lg={5}>
        <Image src={getPicturePath("atelier_interieur_1.jpg")} rounded width="100%" className="mb-3 mt-3" />
      </Col>
      <Col lg={7} className='p-5'>
        <p className="mb-4 justified">
          Le cartonnage c’est la <b>création d’objets raffinés de décoration pour la
          maison</b>. Une décoration personnalisée et unique aux couleurs de votre
          choix. La satisfaction de <b>réaliser soi-même</b> des objets, le plaisir de
          créer, le plaisir d’offrir. Une activité ouverte à tous dans laquelle
          chacun se découvre un talent caché au bout des doigts.{" "}
        </p>
        <p className="mb-4 justified">
          Le cartonnage est une activité très ancienne, nous retrouvons ce savoir
          faire dans le mobilier de Napoléon III, les secrétaires, les travailleuses
          sont un bel exemple de ce travail. Les boîtes des dossiers clients rangées
          dans les meubles de notaires et d’architectes sont aussi de beaux exemples
      de ce magnifique travail.{" "}
        </p>
        <p className="mb-4 justified">
          N’hésitez pas à venir <b>rejoindre l’atelier Hiva Hoa Créations</b>, vous y
      trouverez une très bonne ambiance, des créations toutes simples aux plus
      complexes mais surtout vous rencontrerez des personnes passionnées, une
      entraide, une ambiance chaleureuse et un vrai bien être. Un rendez-vous
      <b>qui mettra de la joie dans vos coeurs</b>.
    </p>
      </Col>
    </Row>
  </Bandeau>
)
export default AtelierDescr
