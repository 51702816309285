import React from "react"
import Bandeau from "../components/bandeau"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function getPicturePath(imgName) {
  const images = require.context('../images/', true);
  return images('./' + imgName)
}



const AtelierTemoignage = () => (
  <Bandeau title="Témoignages" >
    {/* <p>
      Christine C :
    </p>

    <p>
      Claudine B :
    </p>
    <p>Karine M :
    </p> */}


    <Container fluid>
      <br></br>
      <Row>
        <Col sm className="text-center">
          <Image src={getPicturePath("eleve_christine.jpg")} roundedCircle width="50%" className="mb-3 mt-3" />
          <br></br>
          <p>
            <b>Christine C</b>
          </p>
          <p>
            Depuis 2006, je prends un immense plaisir à venir en cours.
            C’est un lieu de partage, de convivialité, de joie, de bienveillance,
            d’apprentissage du cartonnage et de l’encadrement.
            Je trouve auprès de Nadine de bons conseils dans tous les domaines.
          </p>
        </Col>
        <Col sm className="text-center pl-4 pr-4">
          <Image src={getPicturePath("eleve_claudine.jpg")} roundedCircle width="50%" className="mb-3 mt-3" />
          <br></br>
          <p>
            <b>Claudine B</b>
          </p>
          <p>
            Je m’applique à tout oublier pour être sûre de revenir... dans
            ce lieu de convivialité où Nadine transmet son savoir faire dans la joie.
            Le plaisir de créer tout ce que l’on souhaite, tout est possible dès que nous en avons l’envie.
            </p>
        </Col>
        <Col sm className="text-center">

          <Image src={getPicturePath("eleve_karine.jpg")} roundedCircle width="50%" className="mb-3 mt-3" />
          <br></br>
          <p>
            <b>Karine M</b>
          </p>
          <p>
            Pendant les ateliers, on lâche prise,
            on reprend confiance en soi sous la bienveillance de Nadine.
            Elle est très à l’écoute de notre créativité et permet à nos idées de prendre vie.
            Que du bonheur. Merci Nadine
                    </p>
        </Col>
      </Row>
      <br></br>
      <br></br>
    </Container>


  </Bandeau>
)
export default AtelierTemoignage
